import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LOGO from '../../assets/cheetahlogowhite.png';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove user ID and access token from storage (localStorage/sessionStorage)
    localStorage.removeItem('userId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('paymentStatus');
    localStorage.removeItem('activeStep');
    localStorage.removeItem('shippingInfo');
    localStorage.removeItem('students');
    
    // Redirect to login page or home page after logout
    window.location.href = '/';
  };

  const handleButtonClick = (item) => {
    switch (item) {
      case 'Dashboard':
        window.open('https://registration.cheetahlearning.com/login/mycheetahcourses.asp','_blank')
        break;
      case 'Exams':
     
        window.open('https://registration.cheetahlearning.com/login/mycheetah_tabexam.asp','_blank')
        break;
      case 'Referrals':
        
        break;
      case 'Points':
        
        break;
      case 'Transcripts':
        window.open('https://registration.cheetahlearning.com/login/mycheetah_tab4.asp','_blank')
        break;
      case 'Account':
        window.open('https://registration.cheetahlearning.com/login/mycheetah_tab2.asp','_blank')
        break;
     
      case 'Blog':
        window.open('https://www.cheetahlearning.com/wp/blog/', '_blank');
        break;
      case 'Store':
        window.open('https://cheetah-fast-exam-prep.printify.me/products', '_blank');
        break;
      case 'Logout':
        handleLogout();
        break;
      default:
        break;
    }
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#39435f' }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <img src={LOGO} alt="cheetah logo" style={{ maxHeight: '6rem', maxWidth: '11rem' }} />
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
          {['Dashboard', 'Exams', 'Referrals', 'Points', 'Transcripts', 'Account', 'Blog', 'Store', 'Logout'].map((item) => (
              <Button 
                key={item}
                sx={{ 
                  color: 'white', 
                  textTransform: 'none',
                  position: 'relative', 
                  '&:hover': item === 'Referrals' || item === 'Points' ? { 
                    cursor: 'default', 
                    '&::after': {
                      content: '"Coming Soon"',
                      position: 'absolute',
                      top: '100%',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      background: 'rgba(0, 0, 0, 0.8)',
                      color: '#fff',
                      fontWeight:"600",
                      padding: '4px 10px',
                      borderRadius: '4px',
                      boxShadow: '0 3px 7px rgba(0,0,0,0.2)',
                      zIndex: 1,
                      whiteSpace: 'nowrap',
                    },
                  } : {}
                }}
                onClick={() => handleButtonClick(item)}
              >
                {item}
              </Button>
            ))}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
