import React, { useEffect, useState } from 'react';
import styles from './CourseContent.module.css'; // Import the CSS module
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import { SERVER_URL } from "../../constants/envConstants";


const CourseContent = () => {
    const [data, setData] = useState(null);
    const [sectionTitle, setSectionTitle] = useState("Welcome");
    const [iframeSrc, setIframeSrc] = useState(null);
    const [activeSection, setActiveSection] = useState(null); // Track active section


    useEffect(() => {
        async function fetchDataIfNeeded() {
            const queryParams = new URLSearchParams(window.location.search);
            const chatId = '398-PMPVir'
            const result = '398-PMPVir'
            const recid = '74145'
     

            if (chatId === 0) {
                setSectionTitle("Invalid chatId. Please provide a valid chatId.");
                return;
            }

            try {
                const response = await fetch(`${SERVER_URL}/retrieve-json`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ getId: result }),
                });

                const jsonData = await response.json();
                setData(jsonData.data);

                // Set the first section as default
                const firstKey = Object.keys(jsonData.data)[0];
                if (firstKey) {
                    const firstDoc = Object.keys(jsonData.data[firstKey])[0];
                    setActiveSection(firstDoc);
                    setSectionTitle(firstDoc);
                    setIframeSrc(jsonData.data[firstKey][firstDoc]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchDataIfNeeded();
    }, []);

    const handleLinkClick = (docUrl, title) => {
        setIframeSrc(docUrl);
        setSectionTitle(title);
        setActiveSection(title); // Update active section
    };

    return (
        <>
            <Header />
            <div className={styles.container}>
                <div className={styles.nav}>
                    {data &&
                        Object.keys(data).map((section) => (
                            <React.Fragment key={section}>
                                <div className={styles.topicHeading}>{section}</div>
                                {Object.keys(data[section]).map((title) => (
                                    <a
                                        href="#"
                                        key={title}
                                        onClick={() => handleLinkClick(data[section][title], title)}
                                        className={`${styles.navLink} ${
                                            activeSection === title ? styles.activeLink : ""
                                        }`} // Highlight active link
                                    >
                                        {title}
                                    </a>
                                ))}
                            </React.Fragment>
                        ))}
                </div>
                <div className={styles.content}>
                    <span className={styles.sectionTitle}>{sectionTitle}</span>
                    <iframe
                        id="docFrame"
                        src={iframeSrc}
                        className={styles.iframe}
                        title="Document Frame"
                    ></iframe>
                </div>
            </div>
        </>
    );
};

export default CourseContent;
